import { useFocusEffect, useNavigation } from '@react-navigation/native';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Linking, Platform, Pressable, useWindowDimensions } from 'react-native';
import { ScrollView, View, Image } from 'react-native';
import { StylesManager, SurveyModel, QuestionFileModel, QuestionCommentModel } from 'survey-core';
import 'survey-core/survey.i18n';
import showdown from 'showdown';
import 'survey-core/defaultV2.min.css';
import { ReactElementFactory, ReactSurveyElement, Survey } from 'survey-react-ui';
import {
  GetSurveyFromMissionAssignationViewModel,
  MyMissionClient,
  PartialSaveMissionAnswerRequest,
  SaveMissionAnswerDto,
  SurveyFormModel,
} from '../../../api/api.g';
import useApi from '../../../hooks/api-hook';
import { useAppDispatch, useAppSelector } from '../../../hooks/store-hook';
import { authActions } from '../../../models/helpers/platformImportHelper';
import { missionsActions } from '../../../store/missionsSlice';
import Label, { LabelType } from '../Label';
import useCustomTheme from '../../../hooks/theme-hook';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faStore, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import CustomButton from '../CustomButton';
import { useMutation, useQuery } from '@tanstack/react-query';
import { StringHelper } from '../../../helpers/stringHelper';
import useTranslation from '../../../hooks/translation-hook';
import useStyles from '../../../hooks/styles-hook';
import Loading from '../Loading';
import axios, { CancelTokenSource } from 'axios';
import { lightBoxActions } from '../../../store/lightBoxSlice';
import ProfileModal from '../../myaccount/myprofile/ProfileModal';
import SurveyCompleted from './SurveyCompleted';
import SurveyError from './SurveyError';
import ImageZoom from 'react-image-zooom';
import { navBarActions } from '../../../store/navBarSlice';
import { userPrefsActions } from '../../../store/userPrefsSlice';
import { LinearGradient } from 'expo-linear-gradient';

StylesManager.applyTheme('defaultV2');

let formVersionId: string | undefined = undefined;

const SurveyJs = (params) => {
  const t = useTranslation();
  const tMission = useTranslation({ defaultPath: 'mission' });

  const dimension = useWindowDimensions();
  const theme = useCustomTheme();
  const authStore = useAppSelector((selector) => selector.auth);
  const apiMissions = useApi(MyMissionClient);
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const isWebview = params.route.params?.webview;
  const id = params.route.params?.missionAssignationId;
  const lang = params.route.params?.lang;
  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  // const styles = useStyles((selector) => selector.surveyJs);
  const styles = useStyles((section) => section.myMissions);

  let timeoutId;
  let timeoutToken: CancelTokenSource;

  const defaultLogo = <FontAwesomeIcon icon={faStore} style={{ padding: 12, color: theme.text.titleLight.toString(), width: '100%', height: '100%' }} />;
  const [logo, setLogo] = useState<ReactNode>(defaultLogo);
  const [missionSurvey, setMissionSurvey] = useState<GetSurveyFromMissionAssignationViewModel>();
  const [surveyAnswers, setSurveyAnswers] = useState<PartialSaveMissionAnswerRequest[]>();
  const [currentMission, setCurrentMission] = useState<SurveyFormModel>();

  const [survey, setSurvey] = useState<SurveyModel>(undefined);
  const [pageNo, setPageNo] = React.useState(survey?.currentPageNo ?? 0);
  const [hideActionBar, setHideActionBar] = useState(false);
  const [querySeed, setQuerySeed] = useState<string>();
  const [nextNav, setNextNav] = useState(false);

  const [numberOfPage, setNumberOfPage] = useState<number>(undefined);

  const [surveyCompleted, setSurveyCompleted] = useState(false);
  const [surveyError, setSurveyError] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [imageQuestions, setImageQuestions] = useState<any[]>([]);

  useMemo(() => {
    setQuerySeed(StringHelper.generateRandomString());
    setHideActionBar(false);
    setSurveyCompleted(false);
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      dispatch(navBarActions.setVisibility(false));
    }, [])
  );

  useEffect(() => {
    if (lang) {
      dispatch(userPrefsActions.setUiLanguageAndLanguages(lang));
    }
  }, [lang]);

  if (isWebview) {
    dispatch(navBarActions.setIsWebView(true));
  }

  const apiMissionsMutation = useMutation({
    mutationFn: (data: any) => {
      setLoading(true);
      setSurveyError(undefined);

      var i = surveyAnswers.findIndex((a) => a.formVersionId === currentMission.formVersionId);
      var newAnswer = Object.assign(surveyAnswers[i].answer, { answerJson: JSON.stringify(data.survey.data) });

      return apiMissions.saveAndComplete(
        id,
        new Date().getTimezoneOffset(),
        surveyAnswers.map((a) => a.answer),
        apiMissions.tokenSource.token
      );
    },
    onSuccess() {
      setLoading(false);
      dispatch(missionsActions.setMissionAcceptedId(id));
      setSurveyCompleted(true);
      dispatch(missionsActions.setReloadMissionListSeed(StringHelper.generateRandomString()));
    },
    onError(error) {
      // Changing here
      setLoading(false);
      setSurveyError(error);
    },
  });

  const submitSurvey = (enteredSurvey: SurveyModel, options) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutToken?.cancel('Previous token cancelled.');
    }

    if (!currentMission.nextForm) {
      setHideActionBar(true);
      apiMissionsMutation.mutate({ survey: enteredSurvey });
    } else {
      saveSurvey(enteredSurvey, options, currentMission.formVersionId, 0);
      setCurrentMission(currentMission.nextForm);
    }
  };

  const saveSurvey = (survey, options, formVersionId, delay = 1500) => {
    var saveMission = function () {
      var data = this ?? { formVersionId, data, pageNo };

      var i = surveyAnswers.findIndex((a) => a.formVersionId === data.formVersionId);

      var newAnswer = Object.assign(surveyAnswers[i].answer, { answerJson: JSON.stringify(data.data) });
      var pack = Object.assign(new PartialSaveMissionAnswerRequest(), surveyAnswers[i], {
        answer: newAnswer,
        metadataJson: JSON.stringify({ currentPageNo: data.pageNo }),
      });

      surveyAnswers.splice(i, 1, pack);

      var meta = JSON.parse(surveyAnswers[0].metadataJson);
      Object.assign(meta, { currentMission: currentMission.formVersionId });
      surveyAnswers[0].metadataJson = JSON.stringify(meta);

      setSurveyAnswers([...surveyAnswers]);

      apiMissions
        .save(id, new Date().getTimezoneOffset(), surveyAnswers, timeoutToken.token)
        .then(() => {
          timeoutToken = undefined;
        })
        .catch((err) => {
          if (err && err.code == 'ERR_CANCELED') {
            return;
          }

          console.log(err);
        });
    };

    if (surveyAnswers.findIndex((a) => a.formVersionId === currentMission.formVersionId) > -1) {
      if (surveyAnswers[surveyAnswers.findIndex((a) => a.formVersionId === currentMission.formVersionId)].answer) {
        surveyAnswers[surveyAnswers.findIndex((a) => a.formVersionId === currentMission.formVersionId)].answer.answerJson = JSON.stringify(survey.data);
      }
    }

    if (params.route.name == 'survey') {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutToken?.cancel('Previous token cancelled.');
      }

      timeoutToken = axios.CancelToken.source();
      timeoutId = setTimeout(saveMission.bind({ formVersionId: currentMission.formVersionId, data: survey.data, pageNo: survey.currentPageNo }), delay);
    }
  };

  useEffect(() => {
    const token = params.route.params?.token;
    if (token && authStore.idToken == undefined) {
      dispatch(authActions.setToken(token));
    }
  }, [params.route.params?.token, authStore.idToken]);

  const surveyQuery = useQuery({
    enabled: !!authStore.idToken && !!querySeed,
    queryKey: ['surveyQuery', querySeed, authStore.idToken, userPrefs.uiLanguage, id],
    queryFn: ({ signal }) => {
      setLoading(true);
      signal?.addEventListener('abort', () => {
        apiMissions.tokenSource?.cancel('profileQuery - Query was cancelled by React Query');
        apiMissions.onCancel();
      });
      return apiMissions.getMissionSurvey(id, apiMissions.tokenSource?.token);
    },
  });

  const showFileTypeError = () => {
    dispatch(
      lightBoxActions.setContent(
        <ProfileModal hideConfirm>
          <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faTriangleExclamation} size={48} color={theme.colorErrorPrimary.toString()} />
            <Label style={{ marginTop: 8 }}>{t('Please-upload-only-PNG-or-JPEG-files')} </Label>
          </View>
        </ProfileModal>
      )
    );
    dispatch(lightBoxActions.setVisibility(true));
  };

  useEffect(() => {
    const buildPartialSave = (form: SurveyFormModel) => {
      if (!form) {
        return;
      }

      var payload = new PartialSaveMissionAnswerRequest();
      payload.formVersionId = form.formVersionId;
      payload.metadataJson = form.metadataJson;
      payload.answer = new SaveMissionAnswerDto();
      payload.answer.answerJson = form.formAnswerJson;
      payload.answer.missionAssignationId = form.missionAssignationId;
      payload.answer.periodMissionConfigurationId = form.periodMissionConfigurationId;
      payload.answer.rootMissionAssignationId = id;

      if (form.nextForm) {
        return [payload].concat(buildPartialSave(form.nextForm));
      }

      return [payload];
    };

    if (surveyQuery.isSuccess) {
      setMissionSurvey(surveyQuery.data);
      var answers = buildPartialSave(surveyQuery.data.survey);
      setSurveyAnswers(answers);

      let survey = surveyQuery.data.survey;

      do {
        const meta = answers?.length ? JSON.parse(answers[0].metadataJson) : undefined;

        if (survey.formVersionId === meta?.currentMission || !survey.nextForm) {
          setCurrentMission(survey.formVersionId === meta?.currentMission ? survey : surveyQuery.data.survey);
          break;
        }

        survey = survey.nextForm;
      } while (true);
    }

    if (surveyQuery.isError) {
      setLoading(false);
    }

    dispatch(navBarActions.setVisibility(false));
  }, [surveyQuery.isSuccess, surveyQuery.isError, surveyQuery.data]);

  const showImageZoom = (imageSrc) => {
    dispatch(
      lightBoxActions.setContent(
        <View style={{ display: 'flex', minHeight: '100%' }}>
          <ImageZoom alt="" src={imageSrc} zoom="200" />
        </View>
      )
    );
    dispatch(lightBoxActions.setVisibility(true));
  };

  const imageClick = (src, touchTarget) => {
    if (touchTarget === 'sd-file__sign') {
      showImageZoom(src);
    }
  };

  const updateImageQuestions = (question, imgSrc, imgElement) => {
    imgElement.style.cursor = 'pointer';

    const onClick = (e) => {
      imageClick(imgSrc, e.target.className);
    };

    setImageQuestions((prev) => {
      let found = false;
      let imageQuestion: any = {};
      imageQuestion.question = question;
      imageQuestion.eventListener = onClick;

      for (let x of prev) {
        if (x.question.name === question.question.name) {
          imgElement.removeEventListener('click', x.eventListener);
          x = imageQuestion;
          found = true;
          break;
        }
      }

      imgElement.addEventListener('click', imageQuestion.eventListener);

      if (!found) {
        prev.push(imageQuestion);
      }

      return prev;
    });
  };

  useEffect(() => {
    if (currentMission) {
      const newSurvey = new SurveyModel(JSON.parse(currentMission.formJson));

      // Making the survey as 1 question per page
      if (!newSurvey.questionsOnPageMode || newSurvey.questionsOnPageMode !== 'questionPerPage') {
        newSurvey.questionsOnPageMode = 'questionPerPage';
      }

      if (newSurvey.progressBarType === 'pages') {
        if (!ReactElementFactory.Instance.isElementRegistered('sv-progressbar-percentage')) {
          ReactElementFactory.Instance.registerElement('sv-progressbar-percentage', (props) => {
            return React.createElement(PercentageProgressBar, props);
          });
        }

        newSurvey.addLayoutElement({
          id: 'progressbar-percentage',
          component: 'sv-progressbar-percentage',
          container: 'contentTop',
          data: {
            survey: newSurvey,
            chunk: 100 / surveyAnswers.length,
            floorPercentage: () => {
              return surveyAnswers.findIndex((a) => a.formVersionId === currentMission.formVersionId) * (100 / surveyAnswers.length);
            },
          },
        });
      }

      newSurvey.title = '';

      newSurvey.textUpdateMode = 'onTyping';
      newSurvey.showNavigationButtons = 'none';
      newSurvey.showQuestionNumbers = 'off';

      newSurvey.locale = userPrefs.uiLanguage;
      formVersionId = currentMission.formVersionId;

      newSurvey.onAfterRenderQuestion.add(function (s, options) {
        if (numberOfPage === undefined) {
          setNumberOfPage(s.visiblePages.length);
        }
        console.log('render question', options);

        options.htmlElement.querySelectorAll('.sd-element__num').forEach((el) => {
          (el as HTMLElement).style.fontSize = '16px';
          (el as HTMLElement).style.color = 'black';
          (el as HTMLElement).style.padding = '8 6 0 0';
          (el as HTMLElement).style.lineHeight = '15px';
        });

        if (options.question instanceof QuestionFileModel) {
          let htmlElement = options.htmlElement;
          let imgSrc = options.htmlElement.querySelector('img')?.src;

          if (htmlElement && imgSrc && htmlElement.getElementsByClassName('sd-file__sign') && htmlElement.getElementsByClassName('sd-file__sign').length > 0) {
            let imgElement = htmlElement.getElementsByClassName('sd-file__sign')[0];

            updateImageQuestions(options, imgSrc, imgElement);
          }
        }
      });

      newSurvey.onComplete.add(submitSurvey);

      newSurvey.onValueChanged.add((s, question) => {
        console.log('value changed', s, question);
        setNumberOfPage(s.visiblePages.length);

        let valid = true;
        if (question.question instanceof QuestionFileModel) {
          var fileInput = (question?.question as any)?.rootElement?.querySelector('input[type="file"]');
          if (fileInput && question.value && (question?.question as any)?.jsonObj?.acceptedTypes == 'image/*') {
            var file = question.value[0];
            if (file) {
              var fileType = file.type.toLowerCase();
              if (fileType !== 'image/png' && fileType !== 'image/jpeg') {
                showFileTypeError();
                newSurvey.clearValue(question.name);
                valid = false;
              }
            }
          }
        }

        if (valid) {
          saveSurvey(s, question, formVersionId, question.question instanceof QuestionCommentModel ? 3000 : 1500);
        }
      });

      newSurvey.onCurrentPageChanged.add((s, o) => saveSurvey(s, o, formVersionId, 0));

      const converter = new showdown.Converter();
      newSurvey.onTextMarkdown.add(function (survey, options) {
        let str = converter.makeHtml(options.text).trim();

        if (str.startsWith('<p>')) {
          str = str.substring(3);
        }

        if (str.endsWith('</p>')) {
          str = str.substring(0, str.length - 4);
        }

        options.html = str;
      });

      newSurvey.css = newSurvey.onCurrentPageChanged.add((_, options) => {
        setPageNo(options.newCurrentPage.visibleIndex);
      });

      setSurvey(newSurvey);
    }
  }, [currentMission, userPrefs.uiLanguage]);

  useEffect(() => {
    if (!!surveyAnswers) {
      var current = surveyAnswers[surveyAnswers.findIndex((a) => a.formVersionId === currentMission.formVersionId)];
      if (survey && current.answer.answerJson && Object.keys(JSON.parse(current.answer.answerJson))?.length > 1) {
        let pageNumber = 0;
        if (current.metadataJson && !nextNav) {
          let metadata = JSON.parse(current.metadataJson);
          pageNumber = metadata.currentPageNo;
        }

        survey.data = JSON.parse(current.answer.answerJson);
        survey.currentPageNo = pageNumber;

        setNumberOfPage(survey.visiblePageCount);
        setPageNo(survey.currentPageNo);
      } else {
        setPageNo(0);
      }

      if (survey) {
        setLoading(false);
      }
    } else {
      setPageNo(0);
    }
  }, [survey]);

  useEffect(() => {
    if (missionSurvey?.branchInfo?.logoUrl) {
      setLogo(<Image style={{ padding: 12, width: '100%', height: '100%' }} source={{ uri: missionSurvey.branchInfo?.logoUrl }} />);
    } else {
      setLogo(defaultLogo);
    }
  }, [missionSurvey?.branchInfo?.logoUrl]);

  const getEmptyComponent = () => {
    return <SurveyCompleted isWebview={isWebview} />;
  };

  const hasPreviousSurvey = () => {
    return missionSurvey.survey != currentMission;
  };

  const getPreviousSurvey = (f: SurveyFormModel = missionSurvey.survey) => {
    if (f.nextForm == currentMission) {
      return f;
    } else {
      return getPreviousSurvey(f.nextForm);
    }
  };

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <View style={{ flexGrow: 1, flexShrink: 1 }}>
          <ScrollView style={{ flexGrow: 1, flexShrink: 1 }}>
            <>
              <View
                style={{
                  width: '100%' as any,
                  paddingVertical: 16,
                  borderBottomWidth: 1,
                  borderBottomColor: '#ddd',
                  justifyContent: 'center',
                  flexDirection: 'row',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: pageNo === 0 && !hasPreviousSurvey() ? 'center' : 'space-between',
                    width: dimension.width >= 720 ? 640 : '100%',
                    paddingHorizontal: dimension.width >= 720 ? 0 : dimension.width >= 600 ? 40 : 24,
                  }}
                >
                  <View
                    style={{
                      display: 'flex' as any,
                      flexDirection: 'row' as any,
                      alignItems: 'center' as any,
                      justifyContent: 'center' as any,
                      height: 64,
                      width: 64,
                      marginRight: 16,
                      borderRadius: 32,
                      borderWidth: 1,
                      borderColor: theme.borderColorLight,
                      overflow: 'hidden' as any,
                      backgroundColor: theme.background.secondary,
                    }}
                  >
                    {logo}
                  </View>
                  <View style={{ flexGrow: 1, flexShrink: 1 }}>
                    <Label numberOfLines={3} variant={LabelType.titleLargeNoCaps}>
                      {missionSurvey?.subscriptionInfo?.name}
                    </Label>
                    <Label numberOfLines={3} variant={LabelType.titleSmall}>
                      {missionSurvey?.branchInfo?.address}
                    </Label>
                  </View>
                  {dimension.width <= 1200 && (
                    <View style={{ height: '100%' }}>
                      <Pressable
                        onPress={() => {
                          if (isWebview) {
                            (window as any).ReactNativeWebView.postMessage('back');
                          } else {
                            navigation.navigate('mymissions' as never);
                          }
                        }}
                      >
                        <Image style={{ width: 16, height: 16 }} source={require('../../../../assets/close-button.png')} />
                      </Pressable>
                    </View>
                  )}
                </View>
              </View>
              {!surveyError && !surveyCompleted && survey ? (
                <View style={{ alignItems: 'center', position: 'relative' }}>
                  {dimension.width >= 720 && (
                    <View style={{ marginTop: 48, width: 80, position: 'absolute', zIndex: 1000, left: (dimension.width - 640) / 2 - 16 - 80 }}>
                      {(pageNo > 0 || hasPreviousSurvey()) && (
                        <Pressable
                          style={{ alignItems: 'center' }}
                          onPress={() => {
                            console.log(pageNo);
                            setNextNav(false);
                            if (pageNo > 0) {
                              survey?.prevPage();
                            } else {
                              setCurrentMission(getPreviousSurvey());
                            }
                          }}
                        >
                          <Image
                            style={{
                              height: 60,
                              width: 60,
                            }}
                            source={require('../../../../assets/previous-button.png')}
                          />
                          <Label style={{ color: theme.text.darkTurquoiseTwo, textTransform: 'uppercase', marginTop: 4 }}>{t('previous')}</Label>
                        </Pressable>
                      )}
                    </View>
                  )}
                  {dimension.width >= 720 && (
                    <Pressable
                      style={{
                        marginTop: 48,
                        alignItems: 'center',
                        width: 80,
                        position: 'absolute',
                        zIndex: 1000,
                        right: (dimension.width - 640) / 2 - 16 - 80,
                      }}
                      onPress={() => {
                        setNextNav(true);
                        pageNo === numberOfPage - 1 ? survey?.completeLastPage() : survey?.nextPage();
                      }}
                    >
                      <Image
                        style={{
                          height: 60,
                          width: 60,
                        }}
                        source={require('../../../../assets/next-button.png')}
                      />
                      <Label style={{ color: theme.text.darkTurquoiseTwo, textTransform: 'uppercase', marginTop: 4 }}>
                        {t(pageNo === numberOfPage - 1 && !currentMission.nextForm ? 'complete' : 'next')}
                      </Label>
                    </Pressable>
                  )}
                  <Survey model={survey} />
                </View>
              ) : (
                <></>
              )}

              {!surveyError && surveyCompleted && getEmptyComponent()}
              {surveyError && (
                <SurveyError
                  onTryAgainClick={() => {
                    apiMissionsMutation.mutate({ survey: survey });
                  }}
                  onContactClick={() => {
                    const to = 'info@lanla.com';
                    const subject = tMission('mission-failed-title');
                    const body = tMission('survey-error-email-body', { id: id, errorStatus: surveyError.status, errorResponse: surveyError.response });

                    if (Platform.OS === 'android') {
                      Linking.openURL(`mailto:${to}?subject=${subject}&body=${body}`);
                    } else {
                      Linking.openURL(`mailto:${to}?subject=${subject}&body=${body}`);
                    }
                  }}
                />
              )}
            </>
          </ScrollView>
          {!surveyError && !surveyCompleted && !hideActionBar && dimension.width < 720 && (
            <View style={{ flexShrink: 0 }}>
              <LinearGradient
                colors={['#024252', '#035164', '#43A49E']}
                start={{ x: 0, y: -0.5 }}
                end={{ x: 1, y: 1 }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: pageNo === 0 ? 'flex-end' : 'space-between',
                  width: '100%',
                  paddingHorizontal: dimension.width >= 720 ? 0 : dimension.width >= 600 ? 40 : 24,
                  paddingVertical: 10,
                }}
              >
                <View style={{ flexDirection: 'row', gap: 40 }}>
                  <View style={{ width: 60 }}>
                    {pageNo > 0 && (
                      <Pressable
                        onPress={() => {
                          setNextNav(false);
                          if (pageNo > 0) {
                            survey?.prevPage();
                          } else {
                            setCurrentMission(getPreviousSurvey());
                          }
                        }}
                      >
                        <Image
                          style={{
                            height: 60,
                            width: 60,
                          }}
                          source={require('../../../../assets/previous-button.png')}
                        />
                      </Pressable>
                    )}
                  </View>
                  <Pressable
                    onPress={() => {
                      setNextNav(true);
                      pageNo === numberOfPage - 1 ? survey?.completeLastPage() : survey?.nextPage();
                    }}
                  >
                    <Image
                      style={{
                        height: 60,
                        width: 60,
                      }}
                      source={require('../../../../assets/next-button.png')}
                    />
                  </Pressable>
                </View>
              </LinearGradient>
            </View>
          )}
        </View>
      )}
    </>
  );
};

class PercentageProgressBar extends ReactSurveyElement {
  render() {
    const theme = useCustomTheme();
    return (
      <View style={{ width: '100%', position: 'absolute', left: 0, top: 0, height: 3, backgroundColor: theme.borderColorLight }}>
        <View
          style={{
            width: `${(this.props.model.survey.getProgress() / 100) * this.props.model.chunk + this.props.model.floorPercentage()}%` as any,
            backgroundColor: theme.primary ?? ('black' as any),
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 100,
            height: '100%',
          }}
        ></View>
      </View>
    );
  }
}

export default SurveyJs;
