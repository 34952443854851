import React, { ReactElement, useEffect, useRef, useState } from 'react';
import useTranslation from '../../../hooks/translation-hook';
import NavigationElements from '../../../models/NavigationElements';
import InfoBox from '../../common/InfoBox';
import MyAccountPageWrapper from '../MyAccountPageWrapper';
import SettingsOptions from '../../common/SettingsOptions';
import { useAppSelector } from '../../../hooks/store-hook';
import { Platform, ScrollView } from 'react-native';
import ProfileMemberItem from './ProfileMemberItem';
import AddressItem from './AddressItem';
import { faHouse, faLocationDot } from '@fortawesome/pro-regular-svg-icons';
import { MysteryShopperAddressType } from '../../../api/api.g';
import ProfileManager, { ProfileManagerHandles } from './ProfileManager';

const MyProfile = () => {
  const profileManager = useRef<ProfileManagerHandles>(null);
  const t = useTranslation();
  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  const authStore = useAppSelector((selector) => selector.auth);
  const [addresses, setAddresses] = useState(userPrefs.addresses);
  const [addressList, setAddressList] = useState<ReactElement[]>([]);
  const [mfaEnabled, setMfaEnabled] = useState(false);

  useEffect(() => {
    setMfaEnabled(
      Platform.OS == 'web' ? authStore?.multifactor?.signInSecondFactor == 'phone' : authStore?.multifactor?.claims?.firebase?.sign_in_second_factor == 'phone'
    );
  }, [authStore?.multifactor]);

  useEffect(() => {
    setAddresses(userPrefs.addresses);
  }, [userPrefs.addresses]);

  useEffect(() => {
    const addressList = addresses
      ?.filter((a) => a.type !== MysteryShopperAddressType.Current)
      .map((a, i) => {
        return (
          <AddressItem
            key={a.id}
            id={a.id}
            type={a.type}
            city={a.city}
            postalCode={a.postalCode}
            address={a.address}
            department={a.department}
            country={a.country}
            option={`${a.address ? a.address + ', ' : ''}${a.city} ${a.postalCode ? ', ' + a.postalCode : ''}`}
          />
        );
      });

    //const current = addresses?.find((a) => a.type === MysteryShopperAddressType.Current);

    // if (current) {
    //   addressList.unshift(
    //     <AddressItem
    //       readonly
    //       key={'currentAddress'}
    //       id={'currentAddress'}
    //       type={MysteryShopperAddressType.Current}
    //       city={current.city}
    //       postalCode={undefined}
    //       option={`${current.city}`}
    //     />
    //   );
    // }

    setAddressList(addressList ?? []);
  }, [addresses]);

  const getAboutMeMembers = () => {
    return userPrefs.profileMembers
      ?.filter((m) => m.key === 'BirthDate' || m.key === 'Gender')
      .map((m, i) => {
        const selectedValue = userPrefs.profiles?.find((p) => p.key === m.key);
        return (
          <ProfileMemberItem
            bottomBorder
            key={m.key}
            title={m.label}
            value={selectedValue?.value}
            option={selectedValue ? (selectedValue.valueText.trim() !== '' ? selectedValue.valueText : undefined) : undefined}
            profileMember={m}
            onClick={() => {
              profileManager.current?.showProfileMemberPopup({ profileMember: m, value: selectedValue?.value });
            }}
          />
        );
      });
  };

  const getMoreAboutMeMembers = () => {
    return userPrefs.profileMembers
      ?.filter((m) => m.key !== 'BirthDate' && m.key !== 'Gender')
      .map((m, i) => {
        const selectedValue = userPrefs.profiles?.find((p) => p.key === m.key);
        return (
          <ProfileMemberItem
            bottomBorder
            key={m.key}
            title={m.label}
            value={selectedValue?.value}
            option={selectedValue ? (selectedValue.valueText.trim() !== '' ? selectedValue.valueText : undefined) : undefined}
            profileMember={m}
          />
        );
      });
  };

  const handleChangePhoneNumber = () => {
    profileManager.current?.showPhoneNumberPopup();
  };

  const handleChangeName = () => {
    profileManager.current?.showNamePopup();
  };

  return (
    <MyAccountPageWrapper
      navigationTitle={t('my_profile')}
      title={t('my_profile_information')}
      navigationAction={NavigationElements.menu}
      moreInfoComponent={{
        showTitle: true,
        externalLink: t('lanla_website_support'),
        title: t('help_support'),
        children: ``,
      }}
    >
      <ScrollView style={{ paddingBottom: 16 }}>
        <InfoBox icon={faLocationDot} title={t('my_locations')} style={{ marginTop: 16 }}>
          {addressList}
          <AddressItem addButton option={t('add-location')} />
        </InfoBox>
        <InfoBox title={t('about_me')} style={{ marginTop: 32 }}>
          <SettingsOptions
            bottomBorder
            title={t('my_name')}
            option={userPrefs.fullName?.trim() === '' ? t('add') : userPrefs.fullName}
            onPress={handleChangeName}
          />
          <SettingsOptions
            bottomBorder //={userPrefs.profileMembers !== undefined}
            title={t('my_cellphone_number')}
            option={userPrefs.phoneNumber?.trim() === '' ? t('add') : userPrefs.phoneNumber}
            onPress={handleChangePhoneNumber}
            readonly={(mfaEnabled && userPrefs.phoneNumber?.trim() !== '') || userPrefs.phoneNumberVerified == true}
            verified={userPrefs.phoneNumber?.trim() === '' ? undefined : mfaEnabled || userPrefs.phoneNumberVerified == true}
            unverifiedText={`## Phone number unverified

**Freta sanguine** prece non tibi circumfuso socios. Ubi quodsi longo quid
repugnat esse isto modo sensisse ecquis. Securi cremet. Ego poscat omnis, sui
tene faciat in lacrimis sibi. Flectitur pennis tendentes exstitit ordine.`}
            verifiedText={`## Phone number verified

**Freta sanguine** prece non tibi circumfuso socios. Ubi quodsi longo quid
repugnat esse isto modo sensisse ecquis. Securi cremet. Ego poscat omnis, sui
tene faciat in lacrimis sibi. Flectitur pennis tendentes exstitit ordine.`}
          />
          {userPrefs.profileMembers && getAboutMeMembers()}
        </InfoBox>
        <InfoBox title={t('more_about_me')} style={{ marginTop: 32 }}>
          {userPrefs.profileMembers && getMoreAboutMeMembers()}
        </InfoBox>
        <ProfileManager ref={profileManager} />
      </ScrollView>
    </MyAccountPageWrapper>
  );
};

export default MyProfile;
