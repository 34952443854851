import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { useEffect, useState } from 'react';
import { View, Pressable, Image } from 'react-native';
import Label from '../common/Label';
import useTranslation from '../../hooks/translation-hook';
import useCustomTheme from '../../hooks/theme-hook';
import CustomButton from '../common/CustomButton';
import { useAppSelector } from '../../hooks/store-hook';
import { getAuth, RecaptchaVerifier, multiFactor, PhoneAuthProvider, sendEmailVerification, PhoneMultiFactorGenerator } from 'firebase/auth';
import CustomTextInput from '../common/CustomTextInput';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import useApi from '../../hooks/api-hook';
import { MysteryShopperClient } from '../../api/api.g';
import ReauthenticateUserForm from './ReauthenticateUserForm';
import { userPrefsActions } from '../../store/userPrefsSlice';

export interface AccountVerificationPopupProps {
  onClose?(): void;
}

const AccountVerificationPopup = (props: AccountVerificationPopupProps) => {
  const t = useTranslation();
  const theme = useCustomTheme();
  const auth = getAuth();
  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  const authState = useAppSelector((selector) => selector.auth);
  const mysteryShopperApi = useApi(MysteryShopperClient);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [showIntro, setShowIntro] = useState(true);
  const [emailVerified, setEmailVerified] = useState(false);
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [showVerifyPhone, setShowVerifyPhone] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [verificationId, setVerificationId] = useState(null);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [reloginError, setReloginError] = useState(false);
  const [phoneRequired, setPhoneRequired] = useState(false);
  const [invalideCode, setInvalideCode] = useState(false);
  const [showReauthenticateUser, setShowReauthenticateUser] = useState(false);

  useEffect(() => {
    let interval;

    if (showVerifyEmail) {
      if (authState.user.emailVerified) {
        setShowIntro(false);
        setShowVerifyEmail(false);
        setEmailVerified(true);
      }

      interval = setInterval(async () => {
        if (authState.user) {
          await authState.user.reload();
          const updatedUser = auth.currentUser;
          console.log('updatedUser', updatedUser, showVerifyEmail);

          if (updatedUser.emailVerified) {
            setShowIntro(false);
            setShowVerifyEmail(false);
            setEmailVerified(true);
            clearInterval(interval);
          }
        }
      }, 30000); // Check every minute
    }

    // Cleanup subscription and interval on unmount
    return () => {
      clearInterval(interval);
    };
  }, [authState.user, showVerifyEmail]);

  useEffect(() => {
    setRecaptchaVerifier(
      new RecaptchaVerifier(auth, '2fa-recaptcha-container-2fa-popup', {
        size: 'invisible',
        callback: (response) => {},
      })
    );
  }, []);

  const verifyPhoneMutation = useMutation({
    mutationFn: () => {
      return mysteryShopperApi.verifyAccount();
    },
    onSuccess() {
      setShowVerifyPhone(false);
      setPhoneVerified(true);
      dispatch(userPrefsActions.setPhoneNumberVerified(true));
    },
  });

  const sendCode = () => {
    multiFactor(auth.currentUser)
      .getSession()
      .then(function (multiFactorSession) {
        let phoneNumber = `+1${userPrefs.phoneNumber}`;
        phoneNumber = phoneNumber.replace(/[ \-\(\)]/g, '');

        const phoneInfoOptions = {
          phoneNumber: phoneNumber,
          session: multiFactorSession,
        };

        const phoneAuthProvider = new PhoneAuthProvider(auth);
        phoneAuthProvider
          .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
          .then(function (verificationId) {
            setVerificationId(verificationId);
          })
          .catch((error) => {
            if (error.code == 'auth/requires-recent-login') {
              console.log('error', error);
              setShowVerifyPhone(false);
              setReloginError(true);
            }
          });
      });
  };

  const handleResendCode = () => {
    setCodeSent(true);
    sendCode();
  };

  const handleVerifyCode = () => {
    if (isLoading) return;

    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    setIsLoading(true);
    setInvalideCode(false);
    multiFactor(auth.currentUser)
      .enroll(multiFactorAssertion, 'User Phone Number')
      .then(() => {
        verifyPhoneMutation.mutate();
      })
      .catch((error) => {
        setInvalideCode(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOnClosePress = () => {
    props.onClose && props.onClose();
  };

  const handleResendEmailVerification = () => {
    sendEmailVerification(auth.currentUser).then(() => {
      setEmailSent(true);
    });
  };

  const handleStartVerification = () => {
    sendEmailVerification(auth.currentUser);
    setShowIntro(false);
    setShowVerifyEmail(true);
  };

  const handleReauthenticateUser = () => {
    if (userPrefs.phoneNumber) {
      setEmailVerified(false);
      setReloginError(true);
    } else {
      setEmailVerified(false);
      setPhoneRequired(true);
    }
  };

  const handleStartPhoneVerification = () => {
    setShowReauthenticateUser(false);
    sendCode();
    setShowVerifyPhone(true);
  };

  return (
    <View style={{ flexGrow: 1, backgroundColor: '#EAF8FF', width: 350, height: 550 }}>
      <View id="2fa-recaptcha-container-2fa-popup"></View>
      <Image source={require('../../../assets/onBoarding_mobile_4.png')} style={{ aspectRatio: '350/234', width: '100%', height: 'auto' }} />
      <Pressable style={{ position: 'absolute' as any, top: 20, right: 20 }} onPress={handleOnClosePress}>
        <FontAwesomeIcon icon={faXmark} size={30} color={theme.background.primary.toString()} />
      </Pressable>
      {showIntro && (
        <View style={{ flexGrow: 1, flexShrink: 1, marginTop: 8, paddingHorizontal: 16, paddingBottom: 16 }}>
          <View style={{ display: 'flex', flexGrow: 1, flexShrink: 1 }}>
            <Label style={{ fontSize: 28, color: '#1473BD', fontWeight: '600', width: '100%', flexShrink: 0 }} numberOfLines={3}>
              {t('Secure-Your-Account')}
            </Label>
            <Label style={{ marginTop: 12, fontSize: 18, color: '#1C4259', fontWeight: '400', width: '100%', flexShrink: 0 }} numberOfLines={10}>
              {t('Lets-verify-your-email-and-enable-two-factor-authe')}
            </Label>
          </View>
          <CustomButton
            style={{ marginTop: 24, backgroundColor: '#1473BD', borderColor: '#1473BD' }}
            textStyle={{ color: 'white' }}
            title={t('Lets-Do-It')}
            onPress={handleStartVerification}
          />
        </View>
      )}
      {showVerifyEmail && (
        <View style={{ flexGrow: 1, flexShrink: 1, marginTop: 8, paddingHorizontal: 16, paddingBottom: 16 }}>
          <View style={{ display: 'flex', flexGrow: 1, flexShrink: 1 }}>
            <Label style={{ fontSize: 28, color: '#1473BD', fontWeight: '600', width: '100%', flexShrink: 0 }} numberOfLines={3}>
              {t('Verify-Your-Email')}
            </Label>
            <Label style={{ marginTop: 12, fontSize: 18, color: '#1C4259', fontWeight: '400', width: '100%', flexShrink: 0 }} numberOfLines={10}>
              {t('We-have-sent-a-verification-email-to')}
            </Label>
            <Label style={{ marginTop: 12, fontSize: 18, color: '#1C4259', fontWeight: '600', width: '100%', flexShrink: 0 }} numberOfLines={10}>
              {authState.user.email}
            </Label>
          </View>
          <CustomButton
            style={{ marginTop: 24, backgroundColor: '#1473BD', borderColor: '#1473BD', opacity: emailSent ? 0.5 : 1 }}
            textStyle={{ color: 'white' }}
            title={emailSent ? t('Email-sent') : t('Resend-Email')}
            onPress={handleResendEmailVerification}
          />
        </View>
      )}
      {emailVerified && (
        <View style={{ flexGrow: 1, flexShrink: 1, marginTop: 8, paddingHorizontal: 16, paddingBottom: 16 }}>
          <View style={{ display: 'flex', flexGrow: 1, flexShrink: 1 }}>
            <Label style={{ fontSize: 28, color: '#1473BD', fontWeight: '600', width: '100%', flexShrink: 0 }} numberOfLines={3}>
              {t('Your-email-has-been-verified')}
            </Label>
            <Label style={{ marginTop: 12, fontSize: 18, color: '#1C4259', fontWeight: '400', width: '100%', flexShrink: 0 }} numberOfLines={10}>
              {t('Now-lets-enable-two-factor-authentication-2FA-for')}
            </Label>
          </View>
          <CustomButton
            style={{ marginTop: 24, backgroundColor: '#1473BD', borderColor: '#1473BD' }}
            textStyle={{ color: 'white' }}
            title={t('Okay')}
            onPress={handleReauthenticateUser}
          />
        </View>
      )}
      {phoneRequired && (
        <View style={{ flexGrow: 1, flexShrink: 1, marginTop: 8, paddingHorizontal: 16, paddingBottom: 16 }}>
          <View style={{ display: 'flex', flexGrow: 1, flexShrink: 1 }}>
            <Label style={{ fontSize: 28, color: '#1473BD', fontWeight: '600', width: '100%', flexShrink: 0 }} numberOfLines={3}>
              {t('Phone-Number-Required')}
            </Label>
            <Label style={{ marginTop: 12, fontSize: 18, color: '#1C4259', fontWeight: '400', width: '100%', flexShrink: 0 }} numberOfLines={10}>
              {t('Please-add-your-phone-number-in-the-profile-settin')}
            </Label>
          </View>
          <CustomButton
            style={{ marginTop: 24, backgroundColor: '#1473BD', borderColor: '#1473BD' }}
            textStyle={{ color: 'white' }}
            title={t('close')}
            onPress={handleOnClosePress}
          />
        </View>
      )}
      {reloginError && (
        <View style={{ flexGrow: 1, flexShrink: 1, marginTop: 8, paddingHorizontal: 16, paddingBottom: 16 }}>
          <View style={{ display: 'flex', flexGrow: 1, flexShrink: 1 }}>
            <Label style={{ fontSize: 28, color: '#1473BD', fontWeight: '600', width: '100%', flexShrink: 0 }} numberOfLines={3}>
              {t('Reauthenticate')}
            </Label>
            <Label style={{ marginTop: 12, fontSize: 18, color: '#1C4259', fontWeight: '400', width: '100%', flexShrink: 0 }} numberOfLines={10}>
              {t('login.requires-recent-login')}
            </Label>
          </View>
          <CustomButton
            style={{ marginTop: 24, backgroundColor: '#1473BD', borderColor: '#1473BD' }}
            textStyle={{ color: 'white' }}
            title={t('Next')}
            onPress={() => {
              setReloginError(false);
              setShowReauthenticateUser(true);
            }}
          />
        </View>
      )}
      {showReauthenticateUser && <ReauthenticateUserForm onReauthenticate={handleStartPhoneVerification} />}
      {showVerifyPhone && (
        <View style={{ flexGrow: 1, flexShrink: 1, marginTop: 8, paddingHorizontal: 16, paddingBottom: 16 }}>
          <View style={{ display: 'flex', flexGrow: 1, flexShrink: 1 }}>
            <Label style={{ fontSize: 28, color: '#1473BD', fontWeight: '600', width: '100%', flexShrink: 0 }} numberOfLines={3}>
              {t('Verify-Your-Phone-Number')}
            </Label>
            <Label style={{ marginTop: 12, fontSize: 18, color: '#1C4259', fontWeight: '400', width: '100%', flexShrink: 0 }} numberOfLines={10}>
              {t('We-have-sent-a-verification-code-to')}
            </Label>
            <Label style={{ marginTop: 12, fontSize: 18, color: '#1C4259', fontWeight: '600', width: '100%', flexShrink: 0 }} numberOfLines={10}>
              {`+1 ${userPrefs.phoneNumber}`}
            </Label>
          </View>
          <CustomTextInput
            style={{ marginTop: 24 }}
            placeholder={t('Verification-Code')}
            onChange={(e) => {
              setVerificationCode(e.nativeEvent.text);
            }}
            value={verificationCode}
          />
          {invalideCode && (
            <Label style={{ marginTop: 12, fontSize: 18, color: 'red', fontWeight: '400', width: '100%', flexShrink: 0 }}>
              {t('Invalid-verification-code')}
            </Label>
          )}
          {verificationCode.length == 6 ? (
            <CustomButton
              style={{ marginTop: 24, backgroundColor: '#1473BD', borderColor: '#1473BD', opacity: isLoading ? 0.5 : 1 }}
              textStyle={{ color: 'white' }}
              title={t('Verify-Code')}
              onPress={handleVerifyCode}
            />
          ) : (
            <CustomButton
              style={{ marginTop: 24, backgroundColor: '#1473BD', borderColor: '#1473BD', opacity: codeSent ? 0.5 : 1 }}
              textStyle={{ color: 'white' }}
              title={codeSent ? t('Code-sent') : t('Resend-Code')}
              onPress={handleResendCode}
            />
          )}
        </View>
      )}
      {phoneVerified && (
        <View style={{ flexGrow: 1, flexShrink: 1, marginTop: 8, paddingHorizontal: 16, paddingBottom: 16 }}>
          <View style={{ display: 'flex', flexGrow: 1, flexShrink: 1 }}>
            <Label style={{ fontSize: 28, color: '#1473BD', fontWeight: '600', width: '100%', flexShrink: 0 }} numberOfLines={3}>
              {t('Your-phone-number-has-been-verified')}
            </Label>
            <Label style={{ marginTop: 12, fontSize: 18, color: '#1C4259', fontWeight: '400', width: '100%', flexShrink: 0 }} numberOfLines={10}>
              {t('You-have-successfully-enabled-two-factor-authentic')}
            </Label>
          </View>
          <CustomButton
            style={{ marginTop: 24, backgroundColor: '#1473BD', borderColor: '#1473BD' }}
            textStyle={{ color: 'white' }}
            title={t('Done')}
            onPress={() => {
              props.onClose && props.onClose();
            }}
          />
        </View>
      )}
    </View>
  );
};

export default AccountVerificationPopup;
