import React, { forwardRef, ReactElement, useImperativeHandle, useState } from 'react';
import { Modal } from 'react-native';
import Overlay from '../../common/Overlay';
import PhonePopup from './profilepopups/PhonePopup';
import ProfileMemberPopup from './profilepopups/ProfileMemberPopup';
import { ProfileMemberItemProps } from './ProfileMemberItem';
import NamePopup from './profilepopups/NamePopup';

export interface ProfileManagerHandles {
  showNamePopup: () => void;
  showPhoneNumberPopup: () => void;
  showProfileMemberPopup: (profileProps: ProfileMemberItemProps, callback?) => void;
  verifyUserCanAcceptMission: (userPrefs, callback) => boolean;
}

const ProfileManager = forwardRef((props, ref) => {
  const [profileModal, setProfileModal] = useState<ReactElement>();
  const [visible, setVisible] = useState(false);

  const handleOnClose = () => {
    setVisible(false);
  };

  useImperativeHandle(
    ref,
    (): ProfileManagerHandles => {
      return {
        showNamePopup() {
          setProfileModal(<NamePopup onClose={handleOnClose} />);
          setVisible(true);
        },
        showPhoneNumberPopup() {
          setProfileModal(<PhonePopup onClose={handleOnClose} />);
          setVisible(true);
        },
        showProfileMemberPopup(profileProps: ProfileMemberItemProps, callback?) {
          setProfileModal(
            <ProfileMemberPopup
              profileProps={profileProps}
              onClose={handleOnClose}
              onConfirm={() => {
                callback && callback();
              }}
            />
          );
          setVisible(true);
        },
        verifyUserCanAcceptMission(userPrefs, callback) {
          let requiredProfileMembersMaxPage = 0;

          if (userPrefs.firstName === '' || userPrefs.firstName === undefined || userPrefs.lastName === '' || userPrefs.lastName === undefined) {
            requiredProfileMembersMaxPage++;
          }

          if (userPrefs.phoneNumber === undefined || userPrefs.phoneNumber === '') {
            requiredProfileMembersMaxPage++;
          }

          if (userPrefs.profiles?.find((x) => x.key === 'BirthDate') === undefined) {
            requiredProfileMembersMaxPage++;
          }

          if (userPrefs.profiles?.find((x) => x.key === 'Gender') === undefined) {
            requiredProfileMembersMaxPage++;
          }

          if (userPrefs.profiles?.find((x) => x.key === 'EducationLevel') === undefined) {
            requiredProfileMembersMaxPage++;
          }

          if (requiredProfileMembersMaxPage == 0) {
            return true;
          }
          console.log('set callback');
          fillBasicProfileMembers(userPrefs, 1, requiredProfileMembersMaxPage, callback);

          return false;
        },
      };
    },
    []
  );

  const fillBasicProfileMembers = (userPrefs, currentPage, maxPage, callback) => {
    if (userPrefs.firstName === '' || userPrefs.firstName === undefined || userPrefs.lastName === '' || userPrefs.lastName === undefined) {
      setProfileModal(
        <NamePopup
          height={370}
          currentPage={currentPage}
          maxPage={maxPage}
          onClose={handleOnClose}
          onConfirm={(userPrefs) => {
            fillBasicProfileMembers(userPrefs, currentPage + 1, maxPage, callback);
          }}
        />
      );
      setVisible(true);
    } else if (userPrefs.phoneNumber === undefined || userPrefs.phoneNumber === '') {
      setProfileModal(
        <PhonePopup
          height={370}
          currentPage={currentPage}
          maxPage={maxPage}
          onClose={handleOnClose}
          onConfirm={(userPrefs) => {
            fillBasicProfileMembers(userPrefs, currentPage + 1, maxPage, callback);
          }}
        />
      );
      setVisible(true);
    } else {
      let profileMember = undefined;

      if (!userPrefs.profiles?.find((x) => x.key === 'BirthDate')) {
        profileMember = userPrefs.profileMembers.find((m) => m.key === 'BirthDate');
      } else if (!userPrefs.profiles?.find((x) => x.key === 'Gender')) {
        profileMember = userPrefs.profileMembers.find((m) => m.key === 'Gender');
      } else if (!userPrefs.profiles?.find((x) => x.key === 'EducationLevel')) {
        profileMember = userPrefs.profileMembers.find((m) => m.key === 'EducationLevel');
      }

      if (profileMember) {
        let newProps: ProfileMemberItemProps = {
          profileMember: profileMember,
          value: '',
        };

        setProfileModal(
          <ProfileMemberPopup
            profileProps={newProps}
            height={370}
            currentPage={currentPage}
            maxPage={maxPage}
            onClose={handleOnClose}
            onConfirm={(userPrefs) => {
              setTimeout(() => {
                fillBasicProfileMembers(userPrefs, currentPage + 1, maxPage, callback);
              }, 300);
            }}
          />
        );

        setVisible(true);
      } else {
        console.log('call callback', callback);
        callback && callback(userPrefs);
      }
    }
  };

  return (
    <Modal animationType="none" visible={visible} transparent={true}>
      <Overlay showOverLay={true} zIndex={1998} onPress={handleOnClose}>
        {profileModal}
      </Overlay>
    </Modal>
  );
});

export default ProfileManager;
